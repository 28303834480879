<template>
    <div>
            <b-card no-body>
              <b-card-body class="mb-2">
                <b-row>
                  <!-- periode  -->
                  <b-col cols="12" md="3">
                      <b-form-group
                        :label="$t('globalSingular.period')"
                        label-for="period"
                      >
                          <validation-provider
                            #default="{ errors }"
                            name="period"
                            rules="required"
                          >
                          <v-select
                            inputId="period"
                            class="select-size-sm"
                            v-model="form.year"
                            :options="LOV.year"
                            :state="errors.length"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-form-group
                        :label="$t('apps.reportBudget.singular.coa')"
                        label-for="coa"
                      >
                          <v-select
                            inputId="coa"
                            class="select-size-sm"
                            v-model="form.coa"
                            :options="LOV.coaList"
                            :reduce="field => field.id"
                          />
                    </b-form-group>
                  </b-col>
                  <!-- branch -->
                  <b-col cols="12" md="3">
                    <b-form-group
                      :label="$t('globalSingular.branch')"
                      label-for="branch"
                    >
                      <v-select
                        inputId="branch"
                        class="select-size-sm"
                        v-model="form.officeId"
                        :options="LOV.lovBranch"
                        :reduce="field => field.id"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- /type -->
                  <b-col cols="12" md="3">
                    <b-button block variant="primary" @click="showingForm" class="mt-2 pd-1">
                      {{ $t('globalActions.print') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <!-- loading -->
            <div class="text-center text-primary my-2 d-flex flex-column justify-content-center"
                v-if="isLoading">
                  <div class="text mb-1">
                    <b-spinner class="align-middle" />
                  </div>
                  <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
            </div>
            <div class="mt-1 mb-1 d-flex justify-content-end">
                  <!-- button print  -->
                  <b-button variant="outline-primary" @click="print">
                    {{ $t('globalActions.print') }}
                  </b-button>
                  <!-- button export  -->
                  <b-button-group class="ml-1">
                    <b-dropdown right variant="outline-primary">
                        <template #button-content>
                            <feather-icon icon="DownloadIcon"/>
                            {{ $t('globalActions.export') }}
                        </template>
                       <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
                       <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
                    </b-dropdown>
                  </b-button-group>
            </div>
        <!-- Table Container Card -->
        <b-card no-body id="pdfDom">
            <b-card-body class="text-center">
              <h2>{{ companyName }}</h2>
              <h3>{{ $t('apps.reportBudget.singular.titleReport') }}</h3>
              <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
            </b-card-body>
            <b-table
                    id="refListTable"
                    ref="refListTable"
                    no-provider-paging
                    :fields="monthly"
                    :items="itemLists"
                    responsive
                >
                <template #table-busy>
                  <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
                    <div class="text mb-1">
                      <b-spinner class="align-middle" />
                    </div>
                    <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
                  </div>
               </template>

            <template #cell(accounts)="{ item }">
              <span :class="['d-block', 'text-nowrap',  'font-weight-bolder' ]">
                {{ item.accounts }}
              </span>
            </template>

          </b-table>
        </b-card>
    </div>
</template>
<script>
import {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCard,
    BCardBody,
    BRow,
    BTable,
    BCol,
    BFormGroup,
    BSpinner
} from 'bootstrap-vue'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import { ref, onMounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import useHttp from '@/comp-functions/useHttp'
import useFormResource from '@/comp-functions/useFormResource'
import {formatCurrency} from '@/utils/formatter'
import { getYears } from '@/utils'
import { moment } from 'moment'


export default {
  components: {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    ValidationProvider,
    vSelect,
    BSpinner
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { $get } = useHttp()
    const { get } = useFormResource({ url: '' })
    const isLoading = ref(true)
    const {
      isBusy,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
  
  const monthly = [
      { key: 'accounts', label: 'Accounts', thClass: 'text-left', tdClass: 'align-middle text-left' },

      /*bulan Januari */
      { key: 'realisasi_januari_1', label: 'Realization January', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        } },
      { key: 'anggaran_januari_1', label: 'Anggaran January', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_januari_1', label: 'Variance January', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'year_prev_januari_1', label: 'Prev January', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'variances_year_januari_1', label: 'Variances Year January', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan februari */
      { key: 'realisasi_februari_2', label: 'Realization  February', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_februari_2', label: 'Anggaran February', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_februari_2', label: 'Variance February', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'year_prev_februari_2', label: 'Prev February', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_februari_2', label: 'Variances Year Februari', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan maret */
      { key: 'realisasi_maret_3', label: 'Realization March', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_maret_3', label: 'Anggaran March', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_maret_3', label: 'Variance March', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_maret_3', label: 'Prev March', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_maret_3', label: 'Variances Year March', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan april */
      { key: 'realisasi_april_4', label: 'Realization April', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_april_4', label: 'Anggaran April', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_april_4', label: 'Variance April', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_april_4', label: 'Prev April', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_april_4', label: 'Variances Year April', thClass: 'text-right', tdClass: 'align-middle text-right', 
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan Mei */
      { key: 'realisasi_mei_5', label: 'Realization  May', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_mei_5', label: 'Anggaran May', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_mei_5', label: 'Variance May', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_mei_5', label: 'Prev May', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_mei_5', label: 'Variances Year Mei', thClass: 'text-right', tdClass: 'align-middle text-right', 
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan juni */
      { key: 'realisasi_juni_6', label: 'Realization June', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_juni_6', label: 'Anggaran June', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_juni_6', label: 'Variance June', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_juni_6', label: 'Prev June', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_juni_6', label: 'Variances Year Juni', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan juli */
      { key: 'realisasi_juli_7', label: 'Realization July', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_juli_7', label: 'Anggaran July', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_juli_7', label: 'Variance July', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_juli_7', label: 'Prev July', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_juli_7', label: 'Variances Year Juli', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan agustus */
      { key: 'realisasi_agustus_8', label: 'Realization Augustus', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_agustus_8', label: 'Anggaran Augustus', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_agustus_8', label: 'Variance Augustus', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_agustus_8', label: 'Prev Augustus', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_agustus_8', label: 'Variances Year Agustus', thClass: 'text-right', tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value,false)
        }
      },
      
      /* Bulan september */
      { key: 'realisasi_september_9', label: 'Realization September', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_september_9', label: 'Anggaran September', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_september_9', label: 'Variance September', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_september_9', label: 'Prev September', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_september_9', label: 'Variances Year September', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan okctober */
      { key: 'realisasi_oktober_10', label: 'Realization October', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_oktober_10', label: 'Anggaran October', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_oktober_10', label: 'Variance October', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_oktober_10', label: 'Prev October', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_oktober_10', label: 'Variances Year Oktober', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan november */
      { key: 'realisasi_november_11', label: 'Realization November', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'anggaran_november_11', label: 'Anggaran November', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_november_11', label: 'Variance November', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'year_prev_november_11', label: 'Prev November', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },
      { key: 'variances_year_november_11', label: 'Variances Year November', thClass: 'text-right', tdClass: 'align-middle text-right',
          formatter: value => {
          return formatCurrency(value,false)
        }
      },

      /* Bulan desember */
      { key: 'realisasi_desember_12', label: 'Realization December', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'anggaran_desember_12', label: 'Anggaran December', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'variances_desember_12', label: 'Variance December', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'year_prev_desember_12', label: 'Prev December', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      },
      { key: 'variances_year_desember_12', label: 'Variances Year Desember', thClass: 'text-right', tdClass: 'align-middle text-right, tdAttr',
        formatter: value => {
          return formatCurrency(value,false)
        } 
      }

    ]

  const itemLists = ref([])
  const form = ref({
      year: moment().year(),
      periodType: 'MONTHLY',
      coa: null,
      coaList:[],
      officeId: null
  })
    const getData = async () => {
      const years = form.value.year
      const periodTypes = form.value.periodType
      const coa = form.value.coa
      const officeId = form.value.officeId
      const { data } = await $get({ url: `reports/profit-loss/${years}/${periodTypes}/${coa}/${officeId}`})
      isLoading.value = false
      return data
    }

    const getBranch = async () => {
    const { data } = await $get({ url: 'value/getBranch'})
      LOV.value.lovBranch = data
    }

    onMounted(async () => {
     await showingForm()
     await getCoa()
     getBranch()
    })

    const getCoa = async () => {
        LOV.value.coaList = await get({ url: 'value/getCoa/coa'})
    }

    const setPeriodItems = (accounts) => {
        itemLists.value = accounts
    }

    const showingForm = async () => {
        isLoading.value = true
        const accounts = await getData()
        setPeriodItems(accounts)
    }


  const LOV = ref({
       periodType: [
         {
           label: 'MONTHLY',
           value: 'MONTHLY'
         },
         {
           label: 'YEARLY',
           value: 'YEARLY'
         }
       ],
       year: getYears(),
       coaList:[],
       lovBranch:[]
  })

  const periodItems = ref([])

    const tableColoumTitle = [
      { key: 'mother', label: 'Mother', thClass:'', tdClass: '' },
      { key: 'child', label: 'Child', thClass:'', tdClass: '' },
      { key: 'grandson', label: 'GrandSon', thClass:'', tdClass: '' },
      { key: 'realisasi', label: 'Realisasi', thClass:'', tdClass: '' },
      { key: 'anggaran', label: 'Anggaran', thClass:'', tdClass: '' },
      { key: 'variances', label: 'Variances', thClass:'', tdClass: '' },
      { key: 'year_prev', label: 'Year prev', thClass:'', tdClass: '' },
      { key: 'year_prev', label: 'Variances Year', thClass:'', tdClass: '' },
    ]

    return {
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        monthly,
        perPageOptions,
        searchQuery,
        sortBy,
        showingForm,
        LOV,
        isLoading,
        isSortDirDesc,
        refListTable,
        form,
        statusFilter,
        required,
        tableColoumTitle,
        periodItems,
        ...useFilterReport({
        baseUrl: 'reports/budget-report',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
        alert('test to excelnya')
    }
  }
}
</script>
<style lang="scss">
    #balance-sheet-table {
    width: inherit;
    margin: 0 160px 20px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>