<template>
  <div>
    <b-overlay
      :show="showLoading"
      variant="dark"
      :opacity="0.25"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <!-- Bill to Field -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.transactions.sales.singular.billTo')"
              label-for="bill-to"
            >
              <validation-provider
                #default="{ errors }"
                name="billTo"
                rules="required"
              >
                <v-select
                  inputId="bill-to"
                  ref="firstFocusSelectInput"
                  v-model="form.contact_id"
                  :options="LOV.contacts"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Transaction Invoice No. Field -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.transactions.sales.invoice.singular.invoiceNo')"
              label-for="invoice-no"
            >
              <b-form-input
                id="invoice-no"
                v-model="form.code"
                :placeholder="$t('apps.transactions.sales.invoice.placeholder.code')"
                :disabled="actions.isPreview"
              />
            </b-form-group>
          </b-col>

          <!-- Tax Invoice No. Field -->
          <b-col cols="12" offset-md="1" md="4">
            <b-form-group
              :label="$t('apps.transactions.sales.invoice.singular.invoiceTaxNo')"
              label-for="tax-invoice-no"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="tax-invoice-no"
                rules="required"
              > -->
                <b-form-input
                  id="tax-invoice-no"
                  v-model="form.tax_invoice_number"
                  placeholder="000.000-00.00000000"
                  :disabled="actions.isPreview"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-md-2">
          <!-- Invoice Date Field -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.transactions.sales.invoice.singular.invoiceDate')"
              label-for="date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <flat-pickr
                  id="date"
                  :config="dateFormat"
                  v-model="form.date"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Due Date Field -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.transactions.sales.invoice.singular.dueDate')"
              label-for="due-date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <flat-pickr
                  id="due-date"
                  :config="dateFormat"
                  v-model="form.due_date"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Tags Field -->
          <b-col cols="12" md="6">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.tags')"
              label-for="tags"
            >
              <v-select
                v-model="form.tags"
                inputId="tags"
                label="name"
                taggable
                multiple
                push-tags
                :options="LOV.tags"
                :reduce="field => field.id"
                :create-option="field => ({id: Date.now(), name: field})"
                @option:created="pushTags"
                :searchable="!actions.isPreview"
                :selectable="option => !actions.isPreview"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-row >
          <b-col
            cols="12"
          >
            <validation-observer ref="formRef">
              <b-form @submit.prevent>

                <b-table
                  id="refFormTable"
                  ref="refFormTable"
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumns"
                  :items="form.details"
                  primary-key="id"
                  show-empty
                  class="position-relative table-sm"
                >

                  <!-- Custom Header Column -->
                  <template #head(actions)>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="mx-auto"
                    />
                  </template>

                  <!-- Column: Product -->
                  <template #cell(product)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="product"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <v-select
                          v-model="form.details[index].item_id"
                          :options="LOV.products"
                          :reduce="field => field.id"
                          label="name"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                          :clearable="false"
                          @option:selected="setAmountAndUom(index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Column: Product -->
                    <b-form-group>
                      <b-form-input
                        v-model="form.details[index].remark"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        :disabled="actions.isPreview"
                      />
                    </b-form-group>
                  </template>

                  <!-- Column: Unit -->
                  <template #cell(unit)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="unit"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <v-select
                          v-model="form.details[index].uom_id"
                          :options="LOV.uoms"
                          :reduce="field => field.id"
                          label="uom"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Qty -->
                  <template #cell(qty)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="quantity"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <b-form-input
                          class="text-right"
                          type="number"
                          min="1"
                          v-model.number="form.details[index].quantity"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                          :disabled="actions.isPreview"
                          @blur="updateItemAmount(index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Price -->
                  <template #cell(price)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="price"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <cleave
                          :key="index"
                          v-model.number="form.details[index].amount"
                          :state="errors.length > 0 ? false:null"
                          class="text-right form-control"
                          :options="numeric"
                          :disabled="actions.isPreview"
                          :placeholder="actions.isPreview ? null : '100,000'"
                          @blur="updateItemAmount(index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Discount Amount -->
                  <template #cell(discount)="{ index }">
                    <b-form-group>
                      <!-- <validation-provider
                        #default="{ errors }"
                        name="amount"
                        rules="required"
                        :vid="`detail-${index}`"
                      > -->
                        <b-form-input
                          class="text-right"
                          type="number"
                          min="1"
                          v-model.number="form.details[index].disc_percent"
                          :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                          :disabled="actions.isPreview"
                          @blur="updateItemAmount(index)"
                        />
                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </template>

                  <!-- Column: Tax Amount -->
                  <template #cell(tax)="{ index }">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="tax"
                        rules="required"
                        :vid="`detail-${index}`"
                      >
                        <v-select
                          v-model="form.details[index].tax_id"
                          :options="LOV.taxes"
                          :reduce="field => field.id"
                          label="name"
                          :state="errors.length > 0 ? false:null"
                          :disabled="actions.isPreview"
                          :clearable="false"
                          @option:selected="updateItemAmount(index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <!-- Column: Total Amount -->
                  <template #cell(total)="{ index }">
                    <span class="pr-0 text-right align-top border-0 font-weight-bolder d-block text-nowrap form-control">
                      {{ formatCurrency(form.details[index].base_amount) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="{ index }">
                    <b-button
                      variant="flat-danger"
                      class="btn-icon"
                      :disabled="form.details.length <= 2"
                      @click="removeRow(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </template>

                  <!-- Custom Footer -->
                  <template v-slot:custom-foot="{}">
                    <b-tr>
                      <b-td colspan="8" class="p-0">
                        <b-button
                          v-if="!actions.isPreview"
                          variant="outline-primary"
                          block
                          @click="addRow"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                          {{ $t('globalActions.addMore') }}
                        </b-button>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5">&nbsp;</b-td>
                      <b-td class="py-1 pl-5">
                        <div class="mb-0 h5 font-weight-bolder">{{ $t('globalSingular.subTotal') }}</div>
                      </b-td>
                      <b-td class="text-right">
                        <div class="mb-0 h5 font-weight-bolder">{{ formatCurrency(sumOfAmount) }}</div>
                      </b-td>
                      <b-td>&nbsp;</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5" class="border-0">&nbsp;</b-td>
                      <b-td class="pb-1 pl-5 border-0">
                        <div class="mb-0 h6 font-weight-bolder">{{ $t('globalSingular.discount') }}</div>
                      </b-td>
                      <b-td class="text-right align-baseline border-0">
                        <div class="mb-0 h6 font-weight-bolder text-danger"> -{{ formatCurrency( form.sum_of_disc_amount + form.sum_of_disc_global_amount) }}</div>
                      </b-td>
                      <b-td class="border-0">&nbsp;</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5" class="border-0">&nbsp;</b-td>
                      <b-td class="pb-1 pl-5 border-0">
                        <div class="mb-0 h6 font-weight-bolder">{{ $t('globalSingular.taxBase') }}</div>
                      </b-td>
                      <b-td class="text-right align-baseline border-0">
                        <div class="mb-0 h6 font-weight-bolder">{{ formatCurrency( form.sum_of_tax_base_amount) }}</div>
                      </b-td>
                      <b-td class="border-0">&nbsp;</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5" class="border-0">&nbsp;</b-td>
                      <b-td class="pb-1 pl-5 border-0">
                        <div class="mb-0 h6 font-weight-bolder">{{ $t('globalSingular.tax') }}</div>
                      </b-td>
                      <b-td class="text-right align-baseline border-0">
                        <div class="mb-0 h6 font-weight-bolder">{{ formatCurrency( form.sum_of_tax_amount) }}</div>
                      </b-td>
                      <b-td class="border-0">&nbsp;</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5" class="border-0">&nbsp;</b-td>
                      <b-td colspan="2" class="py-0 pl-5 border-0">
                        <hr>
                      </b-td>
                      <b-td class="border-0">&nbsp;</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td colspan="5" class="border-0">
                        &nbsp;
                      </b-td>
                      <b-td class="pl-5 align-baseline border-0">
                        <div class="mb-0 h4 font-weight-bolder">{{ $t('globalSingular.total') }}</div>
                      </b-td>
                      <b-td class="pl-4 text-right align-baseline border-0">
                        <div class="mb-0 h4 font-weight-bolder">{{ formatCurrency( form.sum_of_bill_amount) }}</div>
                      </b-td>
                      <b-td class="border-0">&nbsp;</b-td>
                    </b-tr>
                  </template>
                </b-table>

                <b-row class="my-1">
                  <!-- Description Field -->
                  <b-col cols="12" md="12">
                    <b-form-group
                      :label="$t('apps.transactionsCashbank.singular.notes')"
                      label-for="notes"
                    >
                      <b-form-textarea
                        id="notes"
                        v-model="form.remark"
                        rows="1"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        :disabled="actions.isPreview"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                  <b-button
                    class="mb-sm-1 mb-md-0"
                    :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                    :to="{ name: 'apps-transactions-bacc-transaction-list' }"
                  >
                    <feather-icon
                      icon="ArrowLeftIcon"
                    />
                    {{ $t('globalActions.backToList') }}
                  </b-button>

                  <!-- <b-button
                    v-if="actions.isPreview"
                    class=" mb-sm-1 mb-md-0"
                    variant="outline-danger"
                    @click="handleDelete"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                    {{ $t('globalActions.delete') }}
                  </b-button>

                  <b-button
                    v-if="actions.isPreview"
                    class=" mb-sm-1 mb-md-0"
                    variant="outline-primary"
                    :to="{ name: 'apps-transactions-sales-invoice-prints', params: { id: paramsId }}"
                  >
                    <feather-icon
                      icon="EyeIcon"
                    />
                    {{ $t('globalActions.prints') }}
                  </b-button>

                  <b-button
                    v-if="actions.isPreview && !isInvoiced"
                    class=" mb-sm-1 mb-md-0"
                    variant="success"
                    @click="handleDirectInvoice"
                  >
                    <feather-icon
                      icon="CheckIcon"
                    />
                    {{ $t('globalActions.approveToInvoice') }}
                  </b-button>

                  <b-button
                    v-if="actions.isPreview && isInvoiced"
                    class=" mb-sm-1 mb-md-0"
                    variant="outline-info"
                    :to="{name: 'apps-transactions-sales-payment-add-per-invoice', params: { id: paramsId }}"
                  >
                    <feather-icon
                      icon="CreditCardIcon"
                    />
                    Receive Payment
                  </b-button> -->

                  <b-button-group v-if="!actions.isPreview">
                    <b-dropdown
                      variant="primary"
                      right
                      split
                      @click="handleSubmit"
                    >
                      <template #button-content>
                        <feather-icon
                        icon="SaveIcon"
                      />
                      {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }} Proforma
                      </template>
                      <b-dropdown-item @click="handleDirectInvoice">Create Invoice</b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                  <!-- <b-button
                    v-if="!actions.isPreview"
                    variant="primary"
                    type="submit"
                    @click="handleSubmit"
                  >
                    <feather-icon
                      icon="SaveIcon"
                    />
                    {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                  </b-button> -->

                  <b-button
                    v-else
                    variant="outline-primary"
                    @click="changeToEdit"
                  >
                    <feather-icon
                      icon="EditIcon"
                    />
                    {{ $t('globalActions.changeToEdit') }}
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency } from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'

// import storestate from '@/store/index'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },


  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({
      url: 'transaction/sales/invoice',
      localeContextPath: 'apps.transactions.sales.invoice.singular.invoice',
      redirectPathName: 'apps-transactions-bacc-transaction'
    })

    const { $t } = useLocalization()

    // const companyActive = storestate.state.user.currentCompany

    const LOV = ref({
      products: [],
      contacts: [],
      uoms: [],
      tags: [],
      taxes: []
    })

    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/agent'})
    }

    const getProducts = async () => {
      LOV.value.products = await get({ url: 'value/product?is_internal=true'})
    }

    const getUoms = async () => {
      LOV.value.uoms = await get({ url: 'value/uom'})
    }

    const getTaxes = async () => {
      LOV.value.taxes = await get({ url: 'value/tax'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const tableColumns = ref([])

    const isInvoiced = ref(false)

    const detailInvoice = {
      row_number: 1,
      item_id: 'Product',
      remark: '',
      uom_id: 'Unit',
      quantity: 1,
      amount: 0,
      disc_percent: 0,
      disc_amount: 0,
      base_amount: 0,
      disc_global_percent: 0,
      disc_global_amount: 0,
      tax_base_amount: 0,
      tax_id: null,
      tax_rate: 10,
      tax_amount: 0,
      bill_amount: 0
    }

    const form = ref({
      contact_id: '',
      code: '',
      date: '',
      due_date: '',
      remark: '',
      tags: [],
      tax_invoice_number: '',
      sum_of_quantity: 0,
      sum_of_amount: 0,
      sum_of_disc_amount: 0,
      sum_of_base_amount: 0,
      sum_of_disc_global_amount: 0,
      sum_of_tax_base_amount: 0,
      sum_of_tax_amount: 0,
      sum_of_bill_amount: 0,
      outstanding_amount: 0,
      transaction_type_code: 'BC',
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { ...detailInvoice }
      ]
    })
    const showLoading = ref(false)

    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const sumOfAmount = ref(0)

    const sumItemAmount = () => {
      sumOfAmount.value = form.value.details.reduce((accumulator, {quantity, amount}) => accumulator + (amount * quantity), 0)
      form.value.sum_of_quantity = form.value.details.reduce((accumulator, {quantity}) => accumulator + quantity, 0)
      form.value.sum_of_amount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      form.value.sum_of_disc_amount = form.value.details.reduce((accumulator, {disc_amount}) => accumulator + disc_amount, 0)
      form.value.sum_of_base_amount = form.value.details.reduce((accumulator, {base_amount}) => accumulator + base_amount, 0)
      form.value.sum_of_disc_global_amount = form.value.details.reduce((accumulator, {disc_global_amount}) => accumulator + disc_global_amount, 0)
      form.value.sum_of_tax_base_amount = form.value.details.reduce((accumulator, {tax_base_amount}) => accumulator + tax_base_amount, 0)
      form.value.sum_of_tax_amount = 0
      form.value.sum_of_bill_amount = form.value.details.reduce((accumulator, {bill_amount}) => accumulator + bill_amount, 0)
      form.value.outstanding_amount = form.value.sum_of_bill_amount
    }


    const updateItemAmount = index => {
      const baseAmountBeforeDisc = form.value.details[index].quantity * form.value.details[index].amount

      if (!form.value.details[index].tax_rate) {
        const tax = LOV.value.taxes.find(field => field.id === form.value.details[index].tax_id)
        form.value.details[index].tax_rate = tax.rate
      }

      form.value.details[index].disc_amount = (baseAmountBeforeDisc * form.value.details[index].disc_percent) / 100
      form.value.details[index].base_amount = baseAmountBeforeDisc - form.value.details[index].disc_amount
      form.value.details[index].tax_base_amount = form.value.details[index].base_amount - form.value.details[index].disc_global_amount
      form.value.details[index].tax_amount = 0
      form.value.details[index].bill_amount = form.value.details[index].tax_base_amount
      sumItemAmount()
    }

    // SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
    const setAmountAndUom = index => {
      // @TODO : SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
      const { sell_amount, uom: {id} } = LOV.value.products.find(field => field.id === form.value.details[index].item_id)
      form.value.details[index].uom_id = id
      form.value.details[index].amount = parseInt(sell_amount)
      updateItemAmount(index)
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({
        ...detailInvoice,
        row_number: form.value.details.length + 1,
      })
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      isInvoiced.value = data.is_invoiced
      form.value.contact_id = data.contact_id
      form.value.code = data.code
      form.value.tax_invoice_number = data.tax_invoice_number
      form.value.date = data.date
      form.value.due_date = data.due_date
      form.value.remark = data.remark
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.details = data.details.map(field => {
        const detail = {}
        detail.row_number = field.row_number
        detail.item_id = field.item_id
        detail.remark = field.remark
        detail.uom_id = field.uom_id
        detail.quantity = parseFloat(field.quantity)
        detail.amount = parseFloat(field.amount)
        detail.disc_percent = (parseFloat(field.disc_amount) /  parseFloat(field.amount)) * 100
        detail.disc_amount = parseFloat(field.disc_amount)
        detail.base_amount = parseFloat(field.base_amount)
        detail.disc_global_percent = parseFloat(field.disc_global_percent)
        detail.disc_global_amount = parseFloat(field.disc_global_amount)
        detail.tax_base_amount = parseFloat(field.tax_base_amount)
        detail.tax_id = parseFloat(field.tax_id)
        detail.tax_rate = (parseFloat(field.tax_amount) /  parseFloat(field.dpp_amount)) * 100
        detail.tax_amount = parseFloat(field.tax_amount)
        detail.bill_amount = parseFloat(field.bill_amount)
        return detail
      })
      sumItemAmount()
    }

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
    }

    onMounted(() => {
      getContacts()
      getProducts()
      getUoms()
      getTags()
      getTaxes()

      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }

      tableColumns.value = [
        { key: 'product',  label: $t('apps.transactions.sales.singular.product'), thClass: 'width-30-per', tdClass: 'py-1 align-top pl-0' },
        { key: 'unit', label: $t('apps.transactions.sales.singular.unit'), thClass: 'text-right width-15-per', tdClass: 'py-1 align-top text-right'},
        { key: 'qty', label: $t('apps.transactions.sales.singular.qty'), thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right'},
        { key: 'price', label: $t('apps.transactions.sales.singular.price'), thClass: 'text-right width-20-per', tdClass: 'py-1 align-top text-right'},
        { key: 'discount', label: $t('apps.transactions.sales.singular.discount'), thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right'},
        { key: 'tax', label: $t('apps.transactions.sales.singular.tax'), thClass: 'text-right width-15-per', tdClass: 'py-1 align-top text-right'},
        { key: 'total', label: $t('apps.transactions.sales.singular.total'), thClass: 'text-right width-25-per', tdClass: 'py-1 align-top text-right'},
        { key: 'actions', thClass: 'text-right width-10-per', tdClass: 'py-1 align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      formatCurrency,
      pushTags,
      required,
      LOV,
      tableColumns,
      form,
      addRow,
      removeRow,
      dateFormat,
      sumOfAmount,
      updateItemAmount,
      setAmountAndUom,
      numeric,
      paramsId,
      isInvoiced,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      showLoading,
    }
  },


  methods: {
    async handleDelete () {
      this.showLoading = true
      await this.destroy(this.$swal)
        .finally(() => {
          this.showLoading = false
        })
    },

    async handleSubmit () {
      this.showLoading = true
      await this.store({
        $swal: this.$swal,
        data: this.form
      })
        .finally(() => {
          this.showLoading = false
        })
    },

    async handleDirectInvoice () {
      this.showLoading = true
      this.form.is_invoiced = true
      await this.store({
        $swal: this.$swal,
        data: this.form
      })
        .finally(() => {
          this.showLoading = false
        })
    }
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
