<template>
  <b-card no-body>
    <b-modal
      ref="modal-form"
      v-model="showModalForm"
      centered
      title="Create Role"
      size="lg"
      @hidden="resetForm"
    >
      <validation-observer ref="formRef">
        <b-form @submit.stop.passive="handleSubmit">
          <b-form-group
            :label="$t('globalSingular.name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="errors.length > 0 ? false:null"
                placeholder="Manager"
                :disabled="actions.isPreview"
              />
              <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('globalSingular.description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-input
                id="description"
                v-model="form.description"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('apps.setting.placeholder.description')"
                :disabled="actions.isPreview"
              />
              <small class="text-danger">{{ errors[0]}}</small>
            </validation-provider>
          </b-form-group>

          <b-table
            id="refListTable"
            ref="refListTable"
            no-provider-paging
            no-provider-filtering
            :fields="tableRoles"
            :items="itemRoles"
            responsive
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :busy="isBusy"
            class="position-relative"
            striped
          >
            <template #table-busy>
              <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                <div class="mb-1 text">
                  <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
              </div>
            </template>

            <template #head(#)="{}">
              <b-form-checkbox v-model="isAllMenuChecked" @change="checkedAllMenus" />
            </template>

            <template #cell(#)="{ index }">
              <b-form-checkbox v-model="itemRoles[index].isCheckedAll" @change="checkedAllPermission(index)" />
            </template>

            <!-- Column: menu & permissions -->
            <template #cell(menu)="data">
              <!-- <div class="d-flex justify-content-between align-items-center"> -->
                <span :class="[
                  (data.item.menuLevel.includes('header'))
                    ? 'font-weight-bolder' : '',
                  (data.item.menuLevel === 'header')
                    ? '' : (data.item.menuLevel === 'children' || data.item.menuLevel === 'sub_header')
                      ? 'pl-2' : 'pl-4'
                ]">
                  <span v-if="data.item.menuLevel.includes('children')" style="font-size: 20px">⤷</span>
                  <span v-if="data.item.menuLevel === 'sub_header'" style="font-size: 16px">▼</span>
                  {{ $t(data.item.title) || data.item.header }}
                </span>
                <b-button
                  v-if="!data.item.header"
                  class="btn-icon"
                  variant="flat-secondary">
                </b-button>
              <!-- </div> -->
            </template>
            <!-- Column: menu & permissions -->
            <template #cell(permission)="{ index }">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="itemRoles[index].action"
                  value-field="value"
                  text-field="text"
                  :options="optionPermissions"
                  @change="checkPermissions(index)"
                />
              </b-form-group>
            </template>
          </b-table>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            @click="showModalForm = false"
            class="mr-2"
          >
            Cancel
          </b-button>
          <b-button
            v-if="!actions.isEditMode"
            variant="primary"
            @click="handleSubmit(null)"
          >
            Create
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="handleSubmit(selectedRole.id)"
          >
            Update
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="m-2">
      <b-row align-v="center">
        <b-col cols="12" md="6" class="mb-1 d-flex align-items-center justify-content-start mb-md-0">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="UserCheckIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              {{ $t('apps.setting.singular.roleLists') }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="text-right">
          <b-button size="md" variant="primary" class="btn-icon" @click="showModal">
            <feather-icon icon="PlusIcon" size="16" />
            {{ $t('apps.setting.actions.add') }}
          </b-button>
        </b-col>
      </b-row>

    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #cell(actions)="{ item }">
        <b-button size="sm" variant="flat-primary" class="btn-icon" @click="getSelectedRole(item.id)">
          <feather-icon icon="Edit2Icon" size="16" />
        </b-button>
        <b-button size="sm" variant="flat-danger" class="btn-icon" @click="handleDelete(item.id)">
          <feather-icon icon="XIcon" size="16" />
        </b-button>
      </template>

    </b-table>

  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BRow,
  BModal,
  BCol,
  BCard,
  BButton,
  BTable,
  BSpinner
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useFormResource from '@/comp-functions/useFormResource'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
import useLocalization from '@/comp-functions/utils/useLocalization'
// import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BRow,
    BModal,
    BCol,
    BCard,
    BButton,
    BTable,
    BSpinner,
    ValidationObserver,
    ValidationProvider
  },
  setup () {
    const { toast, $post, $get } = useHttp()
    const { $t } = useLocalization()

    const lists = useListTable({
      url: 'setting/roles'
    })

    const useForm = useFormResource({ url: 'setting/roles', localeContextPath: 'apps.setting.singular.roles' })

    const showModalForm = ref(false)
    const selectedRole = ref()

    const formRef = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'name', label: $t('globalSingular.name') },
      { key: 'description', label: $t('globalSingular.description') },
      { key: 'actions', label: $t('globalSingular.action') }
    ]

    const isAllMenuChecked = ref(false)

    const tableRoles = [
      { key: '#' },
      { key: 'menu', label: $t('globalSingular.menu')},
      { key: 'permission', label: $t('globalSingular.permission')}
    ]

    const itemRoles = ref()

    const optionPermissions = [
      {value: 'create', text: $t('globalSingular.create')},
      {value: 'read', text: $t('globalSingular.read')},
      {value: 'update', text: $t('globalSingular.update')},
      {value: 'delete', text: $t('globalSingular.delete')}
    ]

    const form = ref({
      name: '',
      description: '',
      abilities: []
    })

    const getRoleLists = async () => {
      const { data } = await $get({ url: 'menus' })
      let num = 0 
      itemRoles.value = data.map(field => {
        num += 1
        return {
          id: num,
          // icon: field.icon || '',
          header: Boolean(field.header) || false,
          menuLevel: field.menu_level || '',
          title: field.title || '',
          // code: field.code || '',
          resource: field.resource || '',
          children: field.children?.length > 0 || false,
          isCheckedAll: false,
          subject: field.resource || '',
          action: []
        }
      })
    }

    const checkedAllPermission = index => {
      const values = itemRoles.value[index].isCheckedAll ? ['create', 'read', 'update', 'delete'] : []
      itemRoles.value[index].action = values
    }

    const checkPermissions = index => {
      itemRoles.value[index].isCheckedAll = itemRoles.value[index].action.length > 0
    }

    const checkedAllMenus = () => {
      itemRoles.value.map((field, index) => {
        field.isCheckedAll = isAllMenuChecked.value
        checkedAllPermission(index)
        checkPermissions(index)
        return field
      })
    }

    const generateAbilitesFromTable = () => {
      form.value.abilities = itemRoles.value
        .filter(field => field.isCheckedAll === true)
        .map(field => {
          return {
            subject: field.subject,
            action: field.action
          }
        })
    }

    const getSelectedRole = id => {
      showModalForm.value = true
      useForm.actions.value.isEditMode = true
      selectedRole.value = lists.itemLists.value.find(field => field.id === id)
      form.value.name = selectedRole.value.name
      form.value.description = selectedRole.value.description
      itemRoles.value.map((field) => {
        const ability = selectedRole.value.abilities.find(prop => prop.subject === field.subject)
        if (ability && field.subject === ability.subject) {
          field.action = ability.action
          return field
        }
      })
    }

    const showModal = () => {
      showModalForm.value = true
      useForm.actions.value.isEditMode = false
    }

    const resetForm = () => {
      // useForm.actions.value.isEditMode = false
      form.value.name = ''
      form.value.description = ''
      form.value.abilities = []
      itemRoles.value = itemRoles.value.map(field => {
        field.isCheckedAll = false
        field.action = []
        return field
      })
    }

    onMounted(() => {
      getRoleLists()
    })

    return {
      showModalForm,
      tableColumns,
      tableRoles,
      itemRoles,
      optionPermissions,
      isAllMenuChecked,
      checkedAllMenus,
      checkedAllPermission,
      checkPermissions,
      generateAbilitesFromTable,
      formRef,
      resetForm,
      selectedRole,
      getSelectedRole,
      showModal,
      required,
      form,
      $post,
      toast,
      ...lists,
      ...useForm
    }
  },
  methods: {
    async handleSubmit (id) {
      const url = id ? `setting/roles/${id}` : null
      // this.actions.isEditMode = !!id
      this.generateAbilitesFromTable()
      const form = {...this.form}
      this.store({
        $swal: this.$swal,
        data: form,
        url
      })
      this.showModalForm = false
    },
    handleEdit () {
    },
    async handleDelete (id) {
      await this.destroy(this.$swal, `setting/roles/${id}`)
        .finally(() => {
          this.itemLists = this.itemLists.filter(field => field.id !== id)
        })
    }
  }
}
</script>
 