<template>
    <div>
            <b-card no-body>
              <b-card-body class="mb-2">
                <b-row>
                  <!-- periode  -->
                  <b-col cols="12" md="3">
                      <b-form-group
                        :label="$t('globalSingular.period')"
                        label-for="period"
                      >
                          <validation-provider
                            #default="{ errors }"
                            name="period"
                            rules="required"
                          >
                          <v-select
                            inputId="period"
                            class="select-size-sm"
                            v-model="form.year"
                            :options="LOV.year"
                            :state="errors.length"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>
                  <!-- /periode  -->
                  <!-- month  -->
                    <b-col cols="12" md="3">
                      <b-form-group
                        :label="$t('apps.reportBudget.singular.month')"
                        label-for="period"
                      >
                          <validation-provider
                            #default="{ errors }"
                            name="period"
                            rules="required"
                          >
                          <v-select
                            inputId="period"
                            class="select-size-sm"
                            v-model="form.month"
                            :options="LOV.month"
                            :reduce="field => field.value"
                            :state="errors.length"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </b-form-group>
                  </b-col>

                  <!-- coa -->
                  <b-col cols="12" md="3">
                      <b-form-group
                        :label="$t('apps.reportBudget.singular.coa')"
                        label-for="coa"
                      >
                        <v-select
                            inputId="coa"
                            class="select-size-sm"
                            v-model="form.coa"
                            :options="LOV.coaList"
                            :reduce="field => field.id"
                          />
                      </b-form-group>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-form-group
                        :label="$t('globalSingular.branch')"
                        label-for="branch"
                      >
                        <v-select
                            inputId="coa"
                            class="select-size-sm"
                            v-model="form.officeId"
                            :options="LOV.offices"
                            label="name"
                            :reduce="field => field.id"
                          />
                    </b-form-group>
                  </b-col>
                  
                  <b-col cols="12" md="3">
                    <b-button block variant="primary" @click="showingForm" class="mt-2 pd-1">
                      {{ $t('globalActions.print') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <!-- loading -->
            <div class="text-center text-primary my-2 d-flex flex-column justify-content-center"
                v-if="isLoading">
                  <div class="text mb-1">
                    <b-spinner class="align-middle" />
                  </div>
                  <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
            </div>
            <div class="mt-1 mb-1 d-flex justify-content-end">
                  <!-- button print  -->
                  <b-button variant="outline-primary" @click="print">
                    {{ $t('globalActions.print') }}
                  </b-button>
                  <!-- button export  -->
                  <b-button-group class="ml-1">
                    <b-dropdown right variant="outline-primary">
                        <template #button-content>
                            <feather-icon icon="DownloadIcon"/>
                            {{ $t('globalActions.export') }}
                        </template>
                       <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
                       <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
                    </b-dropdown>
                  </b-button-group>
            </div>
        <!-- Table Container Card -->
        <b-card no-body id="pdfDom">
            <b-card-body class="text-center">
              <h2>{{ companyName }}</h2>
              <h3>{{ $t('apps.reportBudget.singular.titleReportMonthly') }}</h3>
              <h4 v-if="officeReportText" class="mt-sm-1">{{ officeReportText }}</h4>
            </b-card-body>
            <b-table
                    id="refListTable"
                    ref="refListTable"
                    no-provider-paging
                    :fields="monthly"
                    :items="itemLists"
                    responsive
                >
                <template #table-busy>
                  <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
                    <div class="text mb-1">
                      <b-spinner class="align-middle" />
                    </div>
                    <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
                  </div>
               </template>

            <template #cell(accounts)="{ item }">
              <span :class="['d-block', 'text-nowrap',  'font-weight-bolder' ]">
                {{ item.accounts }}
              </span>
            </template>

          </b-table>
        </b-card>
    </div>
</template>
<script>
import {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCard,
    BCardBody,
    BRow,
    BTable,
    BCol,
    BFormGroup,
    BSpinner
} from 'bootstrap-vue'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import { ref, onMounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import useFormResource from '@/comp-functions/useFormResource'
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency} from '@/utils/formatter'
import { getYears } from '@/utils'
import { moment } from 'moment'

export default {
  components: {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    ValidationProvider,
    vSelect,
    BSpinner
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { $get } = useHttp()
    const { get } = useFormResource({ url: '' })

    const isLoading = ref(true)
    const {
      isBusy,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })
  
  const monthly = [
      { key: 'accounts', label: 'Accounts', thClass: 'text-left', tdClass: 'align-middle text-left' },
      { 
        key: 'realisasi', 
        label: 'Realisasi',
        thClass: 'text-right', 
        tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value)
        }
      },
      { key: 'anggaran', 
        label: 'Anggaran', 
        thClass: 'text-right', 
        tdClass: 'align-middle text-right',
        formatter: value => {
          return formatCurrency(value)
        }
      },

    ]

  const itemLists = ref([])
  const form = ref({
      year: moment().year(),
      periodType: 'MONTHLY',
      month: 1,
      coa: null,
      officeId: null
  })
    const getData = async () => {
      const years      = form.value.year
      const month      = form.value.month
      const coa        = form.value.coa
      const officeId   = form.value.officeId
      const { data } = await $get({ url: `reports/budget-reportMonthly/${years}/${month}/${coa}/${officeId}`})
      isLoading.value = false
      return data
    }

    const getOffice = async () => {
      const { data } = await $get({ url: 'master/offices'})
      LOV.value.offices = data
    }

    onMounted(async () => {
     await showingForm()
     await getCoa()
     await getOffice()
      
    })

    const setPeriodItems = (accounts) => {
        itemLists.value = accounts
    }

    const showingForm = async () => {
        isLoading.value = true
        const accounts = await getData()
        setPeriodItems(accounts)
    }


  const LOV = ref({
       periodType: [
         {
           label: 'MONTHLY',
           value: 'MONTHLY'
         },
         {
           label: 'YEARLY',
           value: 'YEARLY'
         }
       ],
       year: getYears(),
       month: [
            {
                label: 'January',
                value: 1
            },
            {
                label: 'February',
                value: 2
            },
            {
                label: 'March',
                value: 3
            },
            {
                label: 'April',
                value: 4
            },
            {
                label: 'May',
                value: 5
            },
            {
                label: 'June',
                value: 6
            },
            {
                label: 'July',
                value: 7
            },
            {
                label: 'Agust',
                value: 8
            },
            {
                label: 'September',
                value: 9
            },
            {
                label: 'October',
                value: 10
            },
            {
                label: 'November',
                value: 11
            },
            {
                label: 'December',
                value: 12
            }
       ],
       coaList:[],
       offices:[]
  })
  const periodItems = ref([])

    const getCoa = async () => {
        LOV.value.coaList = await get({ url: 'value/getCoa/coa'})
    }


    const tableColoumTitle = [
      { key: 'mother', label: 'Mother', thClass:'', tdClass: '' },
      { key: 'child', label: 'Child', thClass:'', tdClass: '' },
      { key: 'grandson', label: 'GrandSon', thClass:'', tdClass: '' },
      { key: 'realisasi', label: 'Realisasi', thClass:'', tdClass: '' },
      { key: 'anggaran', label: 'Anggaran', thClass:'', tdClass: '' }
    ]

    return {
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        monthly,
        perPageOptions,
        searchQuery,
        sortBy,
        showingForm,
        LOV,
        isLoading,
        isSortDirDesc,
        refListTable,
        form,
        statusFilter,
        required,
        tableColoumTitle,
        periodItems,
        
        ...useFilterReport({
        baseUrl: 'reports/budget-report',
        fetchLists
      })
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
        alert('test to excelnya')
    }
  }
}
</script>
<style lang="scss">
    #balance-sheet-table {
    width: inherit;
    margin: 0 160px 20px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>