<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- Code Field -->
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('apps.transactionsCashbankRequest.singular.code')"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    size="sm"
                    v-model="form.code"
                    :disabled="actions.isPreview"
                    :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRequest.placeholder.code') : null"
                  />
                </b-form-group>
              </b-col>
              <!-- Cashbank Account Field -->
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('apps.transactionsCashbankRequest.singular.labelOffices')"
                  label-for="cashbank-office"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cashbank"
                    rules="required"
                  >
                    <v-select
                      inputId="cashbank-office"
                      v-model="form.officeId"
                      :options="LOV.offices"
                      :reduce="field => field.id"
                      label="label"
                      class="select-size-sm"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Month -->
              <b-col cols="3" md="6">
                <b-form-group
                  :label="$t('apps.reportBudget.singular.month')"
                  label-for="month"
                >
                <validation-provider
                            #default="{ errors }"
                            name="period"
                            rules="required"
                    >
                  <v-select
                            inputId="Month"
                            class="select-size-sm"
                            v-model="form.month"
                            :options="LOV.month"
                            :reduce="field => field.value"
                            :state="errors.length"
                            :disabled="actions.isPreview"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- year  -->
              <b-col cols="3" md="6">
                <b-form-group
                  :label="$t('globalSingular.period')"
                  label-for="year"
                >
                <validation-provider
                            #default="{ errors }"
                            name="period"
                            rules="required"
                    >
                  <v-select
                            inputId="Year"
                            class="select-size-sm"
                            v-model="form.year"
                            :options="LOV.year"
                            :state="errors.length"
                            :disabled="actions.isPreview"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('apps.transactionsCashbankRequest.singular.dueDate')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('apps.transactionsCashbankRequest.singular.tags')"
                  label-for="date"
                >
                <v-select
                  v-model="form.tags"
                  inputId="tags"
                  label="name"
                  multiple
                  :options="LOV.tags"
                  :reduce="field => field.id"
                  :searchable="!actions.isPreview"
                  :selectable="option => !actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRequest.singular.tags') : null"
                />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- <b-card-body> -->
            <b-row>
              <!-- Description Field -->
              <b-col cols="12" sm="12">
                <b-form-group
                  :label="$t('apps.transactionsCashbankRequest.singular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    v-model="form.remark"
                    rows="1"
                    :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRequest.placeholder.description') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- </b-card-body> -->

            <b-table
              id="refFormTable"
              ref="refFormTable"
              fixed
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="form.details"
              primary-key="id"
              show-empty
              class="mt-2 position-relative"
            >
              <!-- Custom Header Column -->
              <template #head(actions)>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mx-auto"
                />
              </template>
              <!-- Styling width -->
              <!-- Column: Item -->
              <template #cell(item)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="item"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <v-select
                      id="item"
                      v-model="form.details[index].item"
                      :options="LOV.activity"
                      :reduce="field => field.id"
                      label="label"
                      @option:selected="activityChange(index,LOV.activity,form.details[index].item)"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Description -->
              <template #cell(remark)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <b-form-input
                      v-model="form.details[index].remark"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbankRequest.placeholder.description') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Amount -->
              <template #cell(amount)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].amount"
                      :state="errors.length > 0 ? false:null"
                      class="text-right form-control"
                      :options="numeric"
                      :disabled="actions.isPreview"
                      placeholder="10,000"
                      @blur="sumAmount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="{ index }">
                <b-button
                  variant="flat-danger"
                  class="btn-icon invoice-edit-input"
                  :disabled="form.details.length <= 1"
                  @click="removeRow(index)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </template>
              <!-- Costum Footer -->
              <template v-slot:custom-foot="{}">
                <b-tr>
                  <b-td class="pl-0">
                    <b-button
                      v-if="!actions.isPreview"
                      variant="flat-primary"
                      @click="addRow"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                      {{ $t('globalActions.addMore') }}
                    </b-button>
                  </b-td>
                  <b-td class="text-right">
                    <div class="mb-0 h4 font-weight-bolder">{{ $t('globalSingular.total') }}</div>
                  </b-td>
                  <b-td class="text-right">
                    <div class="mb-0 h4 font-weight-bolder">{{ totalAmount }}</div>
                  </b-td>
                  <b-td>&nbsp;</b-td>
                </b-tr>
              </template>
            </b-table>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-cashbank-request-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <!-- dihide pindah ke modul approval -->

              <!-- <b-button
                v-if="actions.isPreview && isUserApprovers"
                class=" mb-sm-1 mb-md-0"
                variant="success"
                :to="{ name: 'apps-transactions-cashbank-request-approval-detail', params: { id: paramsId }}"
              >
                <feather-icon
                  icon="CheckIcon"
                />
                {{ $t('globalActions.approve') }}
              </b-button> -->

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted, computed } from '@vue/composition-api'
// import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency, formatDatepicker } from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'
import { getYears } from '@/utils'
import { moment } from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // flatPickr,
    Cleave
  },
  setup () {
    const {
      paramsId,
      routeParams,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/cashbank/request', localeContextPath: 'apps.transactionsCashbankRequest.singular.request', redirectPathName: 'apps-transactions-cashbank-request' })

    const { $t } = useLocalization()

    const userApproverIds = ref([])

    const typeIn = ['CI', 'BI']
    const { cashbankRequestId } = routeParams

    const LOV = ref({
      code: '',
      offices: [],
      tags: [],
      year: getYears(),
      month: [
            {
                label: 'January',
                value: 1
            },
            {
                label: 'February',
                value: 2
            },
            {
                label: 'March',
                value: 3
            },
            {
                label: 'April',
                value: 4
            },
            {
                label: 'May',
                value: 5
            },
            {
                label: 'June',
                value: 6
            },
            {
                label: 'July',
                value: 7
            },
            {
                label: 'Agust',
                value: 8
            },
            {
                label: 'September',
                value: 9
            },
            {
                label: 'October',
                value: 10
            },
            {
                label: 'November',
                value: 11
            },
            {
                label: 'December',
                value: 12
            }
       ],
       activity: [],
    })

    const getActivity = async () => {
      LOV.value.activity = await get({ url: 'master/accounts/activity'})
    }

    const getOffices = async () => {
      let companies = storestate.state.user.companies
      let currentCompany = storestate.state.user.currentCompany

      const currentOffice = companies.filter(company => company.company_id == currentCompany.id)

      //condition if user national show all office show office equal user office
      if(storestate.state.user.isNational === true){
        LOV.value.offices = await get({ url: 'master/offices'})
      }else {
        LOV.value.offices = await get({ url: 'master/offices'})
        LOV.value.offices = LOV.value.offices.filter(company => company.id == currentOffice[0].office_id)
      }
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const tableColumns = ref([])

    const form = ref({
      code: '',
      officeId: '',
      tags: '',
      date: formatDatepicker(Date.now()),
      month: '',
      remark: '',
      year: moment().year(),
      // isNational: storestate.state.user.isNational,
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { item: '', remark: '', amount: null }
      ]
    })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      allowInput: true,
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const totalAmount = ref(0)
    const sumAmount = () => {
      form.value.amount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      totalAmount.value = formatCurrency(form.value.amount)
    }

    const userId = storestate.state.user.id

    // will get data to show the preview
    const getDataPreview = async () => {
      const data                  = await show()
      userApproverIds.value       = data.user_approvers_id
      form.value.code             = data.code
      form.value.contactId        = data.contact_id
      form.value.date             = data.date
      form.value.remark           = data.remark
      form.value.amount           = data.amount
      form.value.year             = data.year
      form.value.month            = data.month
      form.value.officeId         = data.office_id
      form.value.tags             = data.tags ? data.tags.map(tag => parseInt(tag)) : []
      totalAmount.value           = formatCurrency(data.amount)
      form.value.details          = data.details
      form.value.details          = form.value.details.map(field => {
        return {
          item: parseFloat(field.item),
          remark: field.remark,
          amount: field.amount,
        }
      })
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({ item: '', remark: '', amount: null})
    }

    const isUserApprovers = computed(() => userApproverIds.value.includes(userId))

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
      sumAmount()
    }


    onMounted(() => {
      getActivity()
      getOffices()
      getTags()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      if (cashbankRequestId) {
        form.value.cashbankRequestId = cashbankRequestId
      }
      if (companyActive.plan_id > 2) {
        // getCashbankCode()
      }
      tableColumns.value = [
        { key: 'item', label: $t('apps.transactionsCashbankRequest.singular.item'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'remark', label: $t('apps.transactionsCashbankRequest.singular.description'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-top'},
        { key: 'amount', label: $t('apps.transactionsCashbankRequest.singular.amount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }
    const activityChange = async (index, option, selected) => {
      option.map(field => {
            if(field.id == selected){
                form.value.details[index].remark = field.description
            }
        })

    }

    return {
      isUserApprovers,
      userApproverIds,
      companyActive,
      pushTags,
      activityChange,
      typeIn,
      required,
      LOV,
      tableColumns,
      form,
      addRow,
      removeRow,
      dateFormat,
      totalAmount,
      sumAmount,
      numeric,
      paramsId,
      cashbankRequestId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.form.details = this.form.details.map(field => {
        field.amount = !this.typeIn.includes(this.cashbankRequestId) ? field.amount * 1 : field.amount * -1
        return field
      })
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
