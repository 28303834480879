<template>
  <b-card title="Filters" class="mb-0">
    <b-form @submit.prevent>
      <b-row>

        <b-col cols="12" md="5">
          <b-form-group :label="$t('globalSingular.period')" label-for="period">
            <validation-provider #default="{ errors }" name="period" rules="required">
              <v-select inputId="period" class="select-size-sm" v-model="form.year" :options="LOV.year"
                :state="errors.length" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- /periode  -->

        <!-- month  -->
        <b-col cols="12" md="5">
          <b-form-group :label="$t('apps.reportBudget.singular.month')" label-for="period">
            <validation-provider #default="{ errors }" name="period" rules="required">
              <v-select inputId="period" class="select-size-sm" v-model="form.month" :options="LOV.month"
                :reduce="field => field.value" :state="errors.length" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="5">
          <b-form-group :label="$t('apps.reportBudget.singular.coa')" label-for="coa">
            <v-select
              inputId="coa"
              class="select-size-sm"
              v-model="form.coa"
              :options="LOV.coaList"
              :reduce="field => field.id"
            />
          </b-form-group>
        </b-col>

        <!-- Button Filter -->
        <b-col cols="12" md="2">
          <b-button block variant="primary" @click="generateEndpointAPI" class="mt-2 pd-1">
            {{ $t('globalActions.find') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import useFormResource from '@/comp-functions/useFormResource'
import { getYears } from '@/utils'
import { moment } from 'moment'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    baseReportUrl: {
      type: String,
      required: true
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider
  },
  setup(props, { emit }) {

    const { get } = useFormResource({ url: '' })

    const form = ref({
      year: moment().year(),
      periodType: 'MONTHLY',
      month: 1,
      coa: null
    })

    const generatedUrl = (year = form.value.year, month = form.value.month, coa = form.value.coa) => {
      return `${props.baseReportUrl}?year=${year}&month=${month}&coa=${coa}`
    }

    const LOV = ref({
      periodType: [
        {
          label: 'MONTHLY',
          value: 'MONTHLY'
        },
        {
          label: 'YEARLY',
          value: 'YEARLY'
        }
      ],
      year: getYears(),
      month: [
        {
          label: 'January',
          value: 1
        },
        {
          label: 'February',
          value: 2
        },
        {
          label: 'March',
          value: 3
        },
        {
          label: 'April',
          value: 4
        },
        {
          label: 'May',
          value: 5
        },
        {
          label: 'June',
          value: 6
        },
        {
          label: 'July',
          value: 7
        },
        {
          label: 'Agust',
          value: 8
        },
        {
          label: 'September',
          value: 9
        },
        {
          label: 'October',
          value: 10
        },
        {
          label: 'November',
          value: 11
        },
        {
          label: 'December',
          value: 12
        }
      ],
       coaList:[]
    })

    const generateEndpointAPI = () => {
      emit('clicked-filter', {
        generatedUrl: generatedUrl()
      })
    }

    const getCoa = async () => {
        LOV.value.coaList = await get({ url: 'value/getCoa/coaLv2'})
    }

    onMounted(async () => {
      generateEndpointAPI()
     await getCoa()
    })

    return {
      form,
      LOV,
      generateEndpointAPI
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
